@import "mixins";

.tariff {
  @extend .bg-primary;
  @extend .text-white;
  padding: 50px 0 !important;

  @include media-breakpoint-up(md) {
    padding: 100px 0 !important;
  }

  &__introduce-text {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  &__about-text {
    margin-bottom: 45px;
  }

  .btn {
    padding: 15px 25px;
    text-transform: uppercase;
    font-family: $primary-font-family;
  }

  &__plans {
    font-family: Karla, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: $white;

    ul {
      list-style: none;
      padding: 0;

      li span {
        font-weight: bold;
      }
    }

    h6 {
      font-family: Vollkorn, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
    }
  }

}