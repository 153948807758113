@import "variables";
@import "mixins";

.questions {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    background: $primary;
  }

  & > * {
    position: relative;
  }

  &__banner {
    background: $white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 28px 0;
    text-align: left;


    @include media-breakpoint-up(md) {
      padding: 55px 100px;
      align-items: center;
    }
  }

  h3 {
    margin-bottom: 15px;
  }

  &__text {
    font-size: 20px;
    line-height: 32px;
    font-family: Karla, sans-serif;
    color: $dark;
  }
}

.questions {
  .btn-accent {
    position: relative;

    &::before {
      content: '';
      background: url("../assets/img/wave-decoration.svg");
      width: 125px;
      height: 48px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-100%, -100%);
    }
  }
}