@import "mixins";

.header {
  position: relative;

  &__video {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: blur(5px);

    &--overlay {
      background: linear-gradient(0deg, rgba(24, 105, 198, 0.6), rgba(24, 105, 198, 0.6));
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  > * {
    position: relative;
  }
}

.flare {
  position: absolute;
  width: 358px;
  height: 448px;
  display: none;
  background: url("../assets/img/flare.svg") no-repeat;
  background-size: contain;
  animation: flare-floating ease-in 5s infinite;

  @include media-breakpoint-up(xl) {
    display: block;
  }

  &--rotated {
    background: url("../assets/img/flare--rorated.svg") no-repeat;
    background-size: contain;
  }
}

@keyframes flare-floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0);
  }
}