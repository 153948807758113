.request-form {

  .modal-dialog {
    max-width: 380px;
  }

  &__title {
    font-family: Vollkorn, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #333333;
    align-self: center;
    justify-self: center;
    width: 100%;
    margin-bottom: 5px;
  }

  &__description {
    font-family: 'Open Sans', 'Karla', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
  }

  &__header {
    justify-content: center;
    border-bottom: 0;
    padding: 27px 10px 10px;
    flex-wrap: wrap;
  }

  &__close-button {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(35%, -35%);
    height: 37px;
    width: 37px;
    background: transparent;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 0;
    max-width: 270px;
    width: 100%;
  }

  &__footer {
    border-top: 0;
    padding: 0 0 20px 0;
    max-width: 270px;
    align-self: center;

    .text-muted {
      font-family: Karla, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #666666;
    }
  }

  button[type=submit] {
    background: #FEE74E;
    border-radius: 7px;
    width: 100%;
    text-align: center;
    padding: 14px 5px;
    border: 0;

    font-family: Vollkorn, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #111111;
  }
}