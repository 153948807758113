@import "libs/bootstrap/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Vollkorn:wght@700&display=swap');

@import "navbar";
@import "article";
@import "trips";
@import "tariff";
@import "questions";
@import "contacts";
@import "header";
@import "request-form";
@import "video-modal";

.btn {
  border-radius: 7px !important;
}

* {
  font-family: 'Karla', sans-serif;
  line-height: 26px;
}

body {
  background-color: #E0EEFF;
}

h1, h2, h3, h4 {
  font-family: 'Vollkorn', serif;
}

.bg-primary-transparent {
  background: rgba($primary, .9);
}


.yacht-card {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__image {
    width: 100%;
  }

  &__info-block {
    width: 71.4%;
    max-height: 0;
    object-fit: cover;
    transition: max-height .5s ease-in;
    display: none;

    @include media-breakpoint-up(md) {
      max-height: 300px;
    }
  }

  &__play {
    position: absolute;
    right: 0;
    top: 15px;
    transform: translateX(50%);
    width: 95px;

    @include media-breakpoint-up(md) {
      width: 144px;
    }
  }

  &__block {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0%, 50%);
    transition: translate .5s ease-in;
    width: 116.8px;

    @include media-breakpoint-up(md) {
      width: 202px;
      bottom: 50%;
      transform: translate(10%, 100%);
    }
  }

  &__anchor {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    width: 55.51px;

    @include media-breakpoint-up(md) {
      width: 96px;
    }
  }

  &__wave {
    position: absolute;
    width: 72px;

    @include media-breakpoint-up(md) {
      width: 125px;
    }

    &--top {
      top: 0;
      left: 0;
      transform: translate(50%, 10%);
    }

    &--right {
      top: 50%;
      right: 0;
      transform: translate(50%, 70%);
    }

    &--bottom {
      bottom: 0;
      left: 50%;
      transform: translate(0, 70%);
    }
  }
}

.header {
  &__navbar {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    transition: background-color .4s ease-in;
    padding: 1rem 0;
  }

  &__lead {
    @extend .lead;
    margin-bottom: 11px;
    max-width: 400px;

    font-family: Karla, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 3px;
    text-transform: uppercase;

    color: $warning;


    @include media-breakpoint-up(md) {
      margin-bottom: 27px;
    }
  }

  &__title {
    @extend .display-1;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: -2px;
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      line-height: 80px;
      margin-bottom: 27px;
    }
  }

  &__text {
    margin-bottom: 20px;
    line-height: 26px;
    font-family: Karla, sans-serif;
    font-size: 16px;

    @include media-breakpoint-up(md) {
      margin-bottom: 55px;
    }
  }
}

.btn-accent {
  font-family: Vollkorn, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  background: #FEE74E;
  border-radius: 7px;
  padding: 14px;

  @include media-breakpoint-up(md) {
    padding: 14px 23px;
  }
}

.header {
  background: url("../assets/img/background.jpg") center center;
  background-size: cover;
}

.section {
  padding: 30px 0;

  @include media-breakpoint-up(md) {
    padding: 50px 0;
  }

  &__description {
    @extend .text-uppercase;
    @extend .fw-bold;

    font-family: Karla, sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 3px;
    color: #1767C4;

    &--yellow {
      @extend .text-warning;
    }
  }

  &__button {
    @extend .btn;
    @extend .btn-lg;
    @extend .btn-outline-primary;
    @extend .fw-bold;
    @extend .text-uppercase;

    font-family: Vollkorn, sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding: 15px 28px;
  }

  &__title {
    @extend .display-4;

    font-family: Vollkorn, sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: -1px;
    color: #333333;

    margin-bottom: 15px;

    &--white {
      @extend .text-white;
    }

    @include media-breakpoint-up(md) {
      line-height: 64px;
      margin-bottom: 52px;
    }
  }
}

.carousel-indicators {
  margin-top: 25px;
  margin-right: unset;
  margin-left: unset;

  button {
    @extend .bg-primary;
    @extend .rounded-circle;
    width: 8px !important;
    height: 8px !important;
    margin: 0 4px !important;
  }
}

.map {
  margin-top: 40px;
  position: relative;


  &::before {
    position: absolute;
    content: '';
    width: 51.47px;
    height: 51.58px;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    display: block;
    background-color: #0a53be;
    background-image: url("../assets/img/map-point-icon.svg");
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    z-index: 100;
  }

  &::after {
    content: '';
    background-image: url("../assets/img/wave-decoration.svg");
    background-size: contain;
    width: 79.18px;
    height: 30.47px;
    display: block;

    position: absolute;
    bottom: -10px;
    left: -10px;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }
}

.ar {
  margin-bottom: 40px;
  width: 100%;
  height: 380px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  color: #fff;
  padding: 38px 32px;

  h3 {
    font-family: Vollkorn, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;
    color: #FFFFFF;

    &::before {
      content: '';
      background-image: url("../assets/img/logo.svg");
      width: 70px;
      height: 21px;
      background-size: contain;
      display: block;
      margin-bottom: 25px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    background: #0a53be;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    z-index: 0;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity .3s;
  }
}

.galley-grid {
  grid-auto-rows: 300px;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  display: none;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 170px;
    display: grid;
  }

  img {
    object-fit: cover;
    height: 100%;
    border-radius: 10px;

    @include media-breakpoint-up(md) {
      &:nth-child(1) {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }

      &:nth-child(9) {
        grid-column: 1 / 3;
        grid-row: 5 / 7;
      }

      &:nth-child(12) {
        grid-column: 4 / 4;
        grid-row: 5 / 7;
      }

      &:nth-child(15) {
        grid-column: 2 / 3;
        grid-row: 7 / 9;
      }

      &:nth-child(16) {
        grid-column: 3 / 5;
        grid-row: 7 / 9;
      }

      &:nth-child(17) {
        grid-column: 1 / 3;
        grid-row: 9 / 11;
      }

      &:nth-child(20) {
        grid-column: 4  / 4;
        grid-row: 9 / 11;
      }

      &:nth-child(4) {
        grid-row: 1 / 3;
        grid-column: 4 / 4;
      }

      &:nth-child(8) {
        grid-column: 3 / 5;
        grid-row: 3 / 5;
      }

      &:nth-child(5) {
        grid-column: 2 /3;
        grid-row: 3 / 5;
      }
    }


  }
}

#gallery {
  img {
    height: 300px;
    object-fit: cover;
  }
}

#yacht-about {
  .to-expand {
    display: none;
  }

  &.expanded .to-expand {
    display: block;

    button[data-toggle=true] {
      display: none;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__annotation {
    color: rgba(255,255,255,.502972);
    font-size: 0.65rem;
  }

  &__links {
    display: grid;
    grid-template-columns: repeat(4, auto);

    & > a {
      margin-right: 10px;
    }
  }

  &__rights {

  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px){
  .footer {
    flex-direction: column;
    text-align: center;

    &__links {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 768px){
  .footer {
    flex-direction: column;
    text-align: center;

    &__links {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(2, auto);
      grid-row-gap: 10px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    flex-direction: column;
    text-align: center;

    &__links {
      display: flex;
      flex-direction: column;

      & > a {
        margin-bottom: 10px;
      }
    }
  }
}

