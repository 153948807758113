@import "libs/bootstrap/mixins";
@import "libs/bootstrap/variables";
@import "mixins";

$transition-speed: .5s;

.article {
  position: relative;
  margin-bottom: 33px;
  border-radius: $article-border-radius;

  h3 {
    position: absolute;
    top: 65%;
    width: 100%;
    padding: 0 23px;

    @include title-font;
    font-size: 20px;
    line-height: 48px;
    letter-spacing: -1px;
    color: $white;

    transition: top $transition-speed;

    @include media-breakpoint-up(md) {;
      font-size: 40px;
      padding: 0 38px;
    }

    &::before {
      content: '';
      background: url("../assets/img/logo.svg");
      width: 70px;
      height: 21px;
      display: block;
      background-size: contain;

      @include media-breakpoint-up(md) {
        margin-bottom: 23px;
      }
    }
  }

  &__title {
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    margin-bottom: 13px;

    img {
      border-radius: $article-border-radius;
      height: 300px;
      width: 100%;
      object-fit: cover;
      transition: opacity $transition-speed, height $transition-speed + .1;

      @include media-breakpoint-up(md) {
        height: 400px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #1767C451;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__text {
    font-family: Karla, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
    padding: 0 10px;

    @include media-breakpoint-up(md) {
      overflow: hidden;
      height: 200px;
      opacity: 0;
      position: absolute;
      bottom: 32px;
      right: 38px;
      left: 38px;
      color: #fff;
      font-family: Karla, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      transition: height $transition-speed, opacity $transition-speed;
      padding: 0;

      p {
        margin-bottom: 3rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    background: linear-gradient(0deg, rgba(23, 103, 196, 0.85), rgba(23, 103, 196, 0.85));

    &:hover {
      .article__text {
        opacity: 1;
      }

      h3 {
        bottom: auto;
        top: 37px;
      }

      img {
        opacity: .2;
      }
    }
  }
}
