.video-modal {
  .modal-dialog {
    max-width: 800px;
    margin: 30px auto;
  }


  .modal-body {
    position: relative;
    padding: 0;
  }

  .embed-responsive {
    height: 450px;
  }
}