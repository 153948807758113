@import "variables";
@import "libs/bootstrap/mixins";

.trips {
  .carousel-indicators {
    margin-top: 25px;
  }
}

.trip {
  &__item {
    border-radius: 10px;
    overflow: hidden;
  }

  &__title {
    font-family: Vollkorn, sans-serif;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1px;
    color: $dark;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px;

    @include media-breakpoint-up(xl) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__about-text {
    font-family: Karla, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: $dark;
    font-size: 16px;
    line-height: 26px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 26px;
      min-height: 135px;
    }

    margin-bottom: 35px;
  }

  &__tour-image {
    display: none;

    @include media-breakpoint-up(xl) {
      margin-bottom: 45px;
      max-height: 100px;
      height: 100%;
      display: block;
    }
  }

  &__content {
    padding: 30px;
    background: $white;

    border-radius: 0 0 10px 10px;

    @include media-breakpoint-up(xl) {
      border-radius: 0 10px 10px 0;
      padding: 70px 90px;
    }
  }

  &__image {
    padding: 0;
    object-fit: cover;
    height: 315px;
    border-radius: 10px 10px 0 0;

    @include media-breakpoint-up(md) {
      border-radius: 10px 0 0 10px;
      height: auto;
    }
  }

  &__details {
    .btn {
      padding: 15px 40px;
      text-transform: uppercase;
      font-size: 14px;
    }

    .btn, h6 {
      font-family: Vollkorn, sans-serif;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    left: 0;
    width: 48px;
    height: 48px;
    background: url("../assets/img/arrow.svg") center center;
    background-size: contain;
    border: 0;
    transform: translate(-50%, -50%);
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    &--right {
      right: 0;
      left: auto;
      transform: translate(50%, -50%) rotate(180deg);
    }
  }
}