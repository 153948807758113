@import "libs/bootstrap/bootstrap";


.navbar {
  &--opened {
    background: $primary !important;
    height: 100vh;
    align-items: flex-start;

    .container {
      height: 100%;
      align-items: flex-start;

      .navbar-collapse.show {
        height: 80%;
        display: flex;
      }
    }
  }

  &__menu {
    flex-direction: column;
    justify-content: space-between;

    .navbar-nav {
      align-items: center;

      @include media-breakpoint-up(md) {
        margin-left: auto;
      }

      .nav-link {
        color: #fff;
        margin: 0 .6rem;

        &--callback {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
          }
        }
      }
    }
  }
}

.btn-callback {
  padding: 15px 23px !important;
  margin-left: 30px;
  color: #fff !important;
  font-family: Vollkorn, sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  display: none;

  @include media-breakpoint-up(md) {
    display: inline;
  }

  &:hover {
    color: #3d3d3d !important;
  }
}

@include media-breakpoint-up(md) {
  .trip .card-body {
    padding: 63px 91px;

    h4 {
      font-family: Vollkorn, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -1px;
      color: #333333;
      margin-bottom: 15px;

    }

    .card-text {
      font-family: Karla, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #333333;
    }
  }
}