@import "variables";

.contacts {
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    background: $primary;
    height: 400px;
  }
}